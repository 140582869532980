<template>
  <div class="about">
    <template v-for="page in aboutData">
      <AboutContent :pageData="page" :key="page.id" />
    </template>
  </div>
</template>

<script>
import AboutContent from "../components/AboutContent";

export default {
  name: "About",
  data: () => {
    return {};
  },
  mounted() {},
  components: {
    AboutContent,
  },
  computed: {
    aboutData() {
      return this.$store.state.aboutData;
    },
  },
};
</script>

<style lang="scss">
</style>
