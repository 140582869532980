<template>
  <section class="aboutcontent" :id="pageData.slug">
    <div
      class="about-row"
      :class="
        'about-row--mt-' + row.marginTop + ' about-row--c-' + row.columns.length
      "
      v-for="row in pageData.content"
      :key="row.id"
    >
      <div class="about-col" v-for="col in row.columns" :key="col.id">
        <template v-for="block in col.content">
          <div
            v-if="block.type == 'textBlock'"
            class="about-text"
            v-html="block.text"
            :key="block.id"
          ></div>
          <figure
            v-if="block.type == 'imageBlock'"
            class="about-image"
            :key="block.id"
          >
            <img
              :src="block.image.url"
              :width="block.image.width"
              :height="block.image.height"
            />
          </figure>
          <div
            v-if="block.type == 'logosBlock'"
            class="about-logos"
            :key="block.id"
          >
            <template v-for="logo in block.logos">
              <a
                v-if="logo.link"
                :key="logo.id"
                :href="logo.link"
                target="_blank"
              >
                <img
                  :alt="logo.alt"
                  :src="logo.image.url"
                  :width="logo.image.width"
                  :height="logo.image.height"
                />
              </a>
              <img
                v-if="!logo.link"
                :key="logo.id"
                :alt="logo.alt"
                :src="logo.image.url"
                :width="logo.image.width"
                :height="logo.image.height"
              />
            </template>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutContent",
  props: ["pageData"],
  data: () => {
    return {};
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
@import "../scss/helpers";

.aboutcontent {
  padding: 0 0;
  box-sizing: border-box;
  border-bottom: 1px solid #000;

  &:last-child {
    border-bottom: none;
  }

  .about-row {
    display: flex;
    margin: 0;

    &--mt-large {
      padding-top: 5.5rem;
    }
    &--mt-small,
    &--mt-null {
      padding-top: 3rem;
    }
    &--c-1 {
      .about-text {
        margin: 0 2.4rem;
      }
    }
    &:first-child {
      padding-top: 5.5rem;
    }
    &:last-child {
      padding-bottom: 5.5rem;
    }
  }
  .about-col {
    flex-grow: 1;
    flex-basis: 0;
    margin: 0 var(--gutter);
  }
  .about-image {
    margin: 0rem 0;
  }
  .about-text {
    margin: 0 1rem;
  }
  .about-image {
    margin: 1rem 0;

    img {
      display: block;
      max-width: 50vw;
      height: auto;
      margin: 0 auto;
    }
  }
  .about-logos {
    max-width: 40rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;

    img {
      height: 1.6rem;
      width: auto;
      margin: 1.5rem;
    }
  }
  p.align-center {
    text-align: center;
    margin: 0 auto;
    margin-top: 1rem;
    max-width: 30rem;
  }
  p {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }
  }
  a {
    text-decoration: underline;
    text-decoration-color: #000;
    text-underline-offset: 0.1rem;
    text-decoration-thickness: 1px;

    &:hover {
      font-style: normal;
    }
  }

  @include bp-s() {
    .about-row {
      flex-wrap: wrap;

      &--mt-large {
        padding-top: 3rem;
      }
      &--mt-small,
      &--mt-null {
        padding-top: 2rem;
      }
      &--c-1 {
        .about-text {
          margin: 0;
        }
      }
      &:first-child {
        padding-top: 3rem;
        padding-top: 2rem;
      }
      &:last-child {
        padding-bottom: 3rem;
      }
    }
    .about-col {
      flex-basis: auto;

      + .about-col {
        margin-top: 1rem;
      }
    }
    .about-image {
      img {
        width: 80%;
        max-width: 15rem;
      }
    }
    .about-text {
      margin: 0;
    }
    p.align-center {
      text-align: left;
      max-width: none;
    }
    .about-logos {
      img {
        height: 1rem;
        margin: 0.5rem 1rem;
      }
    }

    &:last-child {
      padding-bottom: 2rem;
    }
  }
}
</style>
